import NextHead from "next/head"
import { useRouter } from "next/router"
import React from "react"

type HeadProps = {
    title?: string
    description?: string | null
    image?: string | null
    robots?: "index, follow" | "noindex, nofollow" | "index, nofollow"
    canonical?: string
    structuredData?: Array<Object>
    children?: React.ReactNode
}

const Head: React.FC<HeadProps> = ({
    title,
    description,
    image,
    robots,
    canonical,
    structuredData,
    children
}) => {
    const router = useRouter()

    const url = process.env.NEXT_PUBLIC_VERCEL_URL ?? window?.location?.origin
    const defaultStructuredData: Array<object> = [
        {
            "@context": "https://schema.org",
            "@type": "Organization",
            url: `${url}`,
            logo: `https://ik.imagekit.io/ik8qpq4jb/logo-akupanel-store.png?tr=pr-true,f-webp`
        },
        {
            "@context": "https://schema.org",
            "@type": "WebSite",
            url: `${url}`,
            name: "Akupanel Store"
        }
    ]
    return (
        <NextHead>
            <title>{title}</title>
            <meta name="name" itemProp="name" content={title} />
            <meta
                name="description"
                itemProp="description"
                content={description ?? title}
            />
            {image && <meta itemProp="image" content={image} />}
            <meta name="robots" content={robots ?? "index, follow"} />
            <link
                rel="canonical"
                href={
                    canonical && canonical.length > 1
                        ? canonical
                        : url + router.asPath
                }
            />
            <link rel="icon" href="/favicon.ico" />
            <meta property="og:title" content={title} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url + router.asPath} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description ?? title} />
            {image && <meta name="twitter:image" content={image} />}
            {image && <meta property="og:image" content={image} />}
            {children && children}
            {defaultStructuredData
                .concat(structuredData ?? [])
                .map((data, i) => (
                    <script
                        key={`cs-${i}`}
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{
                            __html: JSON.stringify(data)
                        }}
                    />
                ))}
        </NextHead>
    )
}

export default Head
